import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "python *args and **kwargs explained",
  "author": "tzookb",
  "type": "post",
  "date": "2018-10-30T05:31:12.000Z",
  "url": "/2018/10/python-args-and-kwargs-explained/",
  "categories": ["Python"],
  "tags": ["productivity", "programing", "python"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You are entering a new codebase, the code looks good, well organized and well commented.`}</p>
    <p>{`Then you notice a few methods with these weird `}<inlineCode parentName="p">{`*args, **kwargs`}</inlineCode></p>
    <p>{`?!?!?!`}</p>
    <p>{`then you dive into that function and see it passes those forward to other function or handling those weird params.`}</p>
    <p>{`So`}{`…`}{` what are those?`}</p>
    <p><inlineCode parentName="p">{`*args`}</inlineCode>{` `}{`–`}</p>
    <p>{`that is the shorthand of accessing an unknown amount of params in the function. For example, let`}{`’`}{`s create a `}<inlineCode parentName="p">{`avg`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{}}>{`def avg_numbers(*args):
    return sum(args) / len(args)

res = sum_numbers(5,1,1,2,6)
`}</code></pre>
    <p>{`So as you can see here, I can pass how many variables I want to the `}<inlineCode parentName="p">{`avg_numbers`}</inlineCode>{` function and it will be handled properly. You can always use the normal method params with `}<inlineCode parentName="p">{`*args`}</inlineCode>{` like this example`}</p>
    <pre><code parentName="pre" {...{}}>{`def first_and_others(first, *args):
    print(first)
    print(" ".join(args)) 

first_and_others("Im First", "the", "rest", "of", "us")
`}</code></pre>
    <p>{`the above function will print:`}</p>
    <pre><code parentName="pre" {...{}}>{`ImFirst
the rest of us
`}</code></pre>
    <p><inlineCode parentName="p">{`**kwargs`}</inlineCode>{` `}{`–`}</p>
    <p>{`that is for accessing the keyworded params as a dictionary. Lets start with a simple example:`}</p>
    <pre><code parentName="pre" {...{}}>{`def first_and_second(**kwargs):
    print(kwargs["first"])
    print(kwargs["sec"])

first_and_second(first="sss", sec="cxc")
`}</code></pre>
    <div>
    </div>
    <div>
  As you can see here we get easy access to the passed params.
    </div>
    <div>
    </div>
    <div>
  Important note, you are not forced to use <code>*args, **kwargs</code> as the variable names, you can use <code>*big_list, **big_dict</code> it&#8217;s only a convention in the python world. What you are forced to have are thos single and double <code>*</code>
    </div>
    <div>
    </div>
    <div>
  The second note is that you can can use different variations of those two &#8220;magic&#8221; params. You can have only <code>*args</code> or only <code>*kwargs</code> or a mix with the &#8220;regular&#8221; params we all know
    </div>
    <div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      